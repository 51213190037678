import React from 'react'

import './menu.css';

const Menu = ( props ) => {
  return (
    <div id="menu">
      <input className="burger-check" id="burger-check" type="checkbox" />
      <label htmlFor="burger-check" className="burger"></label>
        <nav id="navigation1" className="navigation">
          <ul>
            <li><a href="/">Homepage</a></li>
          <li><a href="/skills">Skills</a></li>
          <li><a href="/writing">Blog, Notes & Writing</a></li>
          <li><a href="/projects">Projects</a></li>
          <li><a href="/frontend-apps">Frontend Apps</a></li>
          </ul>
        </nav>
    </div>
  )
}


export default Menu